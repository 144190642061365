table {
    th {
        color: white !important;
        background-color: #00233d !important;
    }
    .mat-row:hover {
        background-color: rgba(180, 180, 180, 0.5) !important;
        cursor: pointer;
    }
}
