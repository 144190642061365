@mixin bh-search-bar-theme($theme) {

    $background: map-get($theme, background);

    .bh-search-bar {

        &.expanded {
            background-color: map-get($background, background);
        }
    }
}