// Import Bh core library
@import '@bh/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

.full-width {
    width: 100%;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 100;
}

.page-header {
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
}

.dialog-header {
    font-size: 2.2rem;
    font-weight: 600;
}

.actions {
    width: 120px;
    text-align: right;
}

.pill {
    border-radius: 5px;
    padding: 3px;
    color: white !important;
}

.sm-text {
    font-size: 0.9rem;
}

.image-viewer .mat-dialog-container {
    width: 100vw;
    height: 100vh;
    padding: 0px;
    margin: 0px;
}

.dashboard-card-title {
    font-size: 1.5rem;
    font-weight: 800;
}

.thumbnail {
    min-width: 45px;
    min-height: 45px;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 5px;
    margin: 2px;
}

.clickable {
    cursor: pointer;
}

.bg-white {
    background-color: white;
}

.w-full {
    width: 100%;
}

mat-label {
    color: #7a7b80 !important;
}
