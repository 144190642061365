@mixin bh-sidebar-theme($theme) {

    $background: map-get($theme, background);

    bh-sidebar {
        background: map-get($background, background);
    }

    .bh-sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.6);

        &.bh-sidebar-overlay-invisible {
            background-color: transparent;
        }
    }
}